import React from 'react';

import {
  CategoriesHeader,
  SecondaryButton,
  usePrice,
  LoyaltyBanner,
} from '@fingermarkglobal/cringer.components';
import { ArrowButton } from '../basket-action/';

const Header = ({
  title = null,
  total = null,
  className = '',
  showCategoriesHeader = true,
  previousAction = null,
  allowPreviousAction = true,
  isLogoEnabled = true,
  isTitleEnabled = true,
  nextAction = null,
  allowNextAction = true,
  nextActionClass = '',
  showSubtotal = true,
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
  computedAction = () => {},
  showLoyaltyBanner = false,
} = {}) => {
  const { formatPrice } = usePrice();
  return (
    <div data-test="header" className={`flex flex-col bg-header ${className}`}>
      {showCategoriesHeader && <CategoriesHeader isItemRounded={false} className="text-white" />}
      {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
      {!showCategoriesHeader && (
        <div className={`flex justify-center mb-2 p-6`}>
          {isLogoEnabled && <div className="h-32 bg-no-repeat bg-cover w-80 bg-logo" />}
        </div>
      )}
      <div className="flex justify-between p-6 bg-nav">
        <div className="flex justify-start">
          {previousAction && (
            <ArrowButton
              disabled={!allowPreviousAction}
              onClick={previousAction}
              className="mr-6"
              isArrowInverse={true}
              isNextAction={false}
              data-test="arrow-back-button"
            />
          )}
        </div>
        <div className="self-center">
          {isTitleEnabled && (
            <h1 className="text-5xl font-bold text-center font-header">{title}</h1>
          )}
        </div>
        <div className="flex justify-end space-x-4">
          {showSubtotal && (
            <p
              className="self-center text-5xl font-bold text-center break-words"
              data-test="header-cart-subtotal"
            >
              {formatPrice({ price: total })}
            </p>
          )}
          {nextAction && (
            <ArrowButton
              disabled={!allowNextAction}
              onClick={nextAction}
              className={`ml-6 ${nextActionClass}`}
              data-test="arrow-next-button"
            />
          )}
          {isCouponEnabled && showCouponButton && (
            <SecondaryButton
              className="py-0.5 font-bold text-4xl ml-8"
              onClick={couponAction}
              data-test="enter-offer-button"
            >
              {couponText}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
