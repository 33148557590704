import React, { useMemo } from 'react';

import {
  BannerWarning,
  PrimaryButton,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackgroundImage from '../../../assets/images/HardeesBG.jpg';

const PrimaryHome = ({
  t = () => {},
  healthMsg = true,
  languages = [],
  primaryAction,
  isCurrentLang,
  secondaryAction,
  accessibilityEnabled,
  onChangeLanguage = () => {},
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const secondaryActionClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-button-large' : 'text-button-large px-20';
  }, [isCurrentLang]);

  const marginBottom = showTermsAndConditionsButton && healthMsg ? '136' : '104';

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className="z-10 flex flex-col items-center justify-center w-full h-full"
      >
        <div
          data-test="home-upper"
          className={`absolute bottom-0 flex items-center justify-center w-full space-x-12 mb-${marginBottom}`}
        >
          {!!primaryAction && (
            <PrimaryButton onClick={primaryAction} className="w-2/5 px-20 py-6 text-button-large">
              {t('homePrimaryAction')}
            </PrimaryButton>
          )}

          {!!secondaryAction && (
            <PrimaryButton
              data-test="button-secondary"
              className={`w-2/5 py-6 ${secondaryActionClass}`}
              onClick={secondaryAction}
            >
              {t('homeSecondaryAction')}
            </PrimaryButton>
          )}
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className="flex-col w-11/12 mb-24 sm:flex-row sm:w-10/12 md:w-4/5 md:mb-48 md:flex"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`flex-col w-11/12 mb-${
                showTermsAndConditionsButton ? '2' : '24'
              } sm:flex-row sm:w-10/12 md:w-4/5 md:mb-${
                showTermsAndConditionsButton ? '2' : '48'
              } md:flex`}
            />
          )}
        </div>

        <div
          data-test="home-footer"
          className="absolute bottom-0 flex flex-col items-center justify-center w-full mb-12 space-y-8"
        >
          <p className={`text-5xl tracking-wide ${accessibilityEnabled ? 'mb-10' : ''}`}>
            {t('languageSelectionTitle')}
          </p>

          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
